import _ from "lodash";
import {
  LOGIN,
  SIGNUP,
  SET_SIGNUP_NOTICE,
  LOGOUT,
  SIGNUP_ERROR,
  LOGIN_ERROR,
  SET_ONBOARDING,
  SET_USER,
  SET_DB_USER_PROPS,
} from "../../../redux/actions/Auth";

const initialState = {
  authenticated: null,
  onboarding: null,
  error: "",
  login_error: "",
  signup_error: "",
};

const Auth = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        authenticated: true,
        login_error: "",
        user: action.payload,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        authenticated: false,
        login_error: action.payload.message,
      };
    case SIGNUP_ERROR:
      return {
        ...state,
        authenticated: false,
        signup_error: action.payload.message,
      };
    case SET_SIGNUP_NOTICE:
      return {
        ...state,
        ...action.payload,
      };
    case SIGNUP:
      return {
        ...state,
        authenticated: true,
        onboarding: true,
        signup_error: "",
        user: action.payload,
      };
    case LOGOUT:
      return { ...state, authenticated: false, user: undefined };
    case SET_ONBOARDING:
      return { ...state, onboarding: action.payload };
    case SET_USER:
      newState = { ...state };
      _.set(newState, "user.db_user", action.payload);
      return newState;
    case SET_DB_USER_PROPS:
      newState = { ...state };
      let db_user = newState?.user?.db_user;
      if (db_user) {
        Object.keys(action.payload).forEach((key) => {
          db_user[key] = action.payload[key];
        });
      }
      return newState;
    default:
      return state;
  }
};
export default Auth;
