import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { esolCourseIds } from "api/Consts";
import userSvc from "services/User";

const EsolAdvertisement = ({ slide }) => {
  const history = useHistory();
  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  async function insertTagUser() {
    console.log("hey i am here insert user");

    await userSvc
      .createUserTag({
        name: "esol_interest",
        value: "1",
        user_id: userId,
      })
      .catch((err) => {
        console.log("Couldn't insert tag");
        console.error(err);
      });
  }

  const handleCheckoutPage = (courseId) => {
    console.log("courseId", courseId);

    if (
      courseId === esolCourseIds?.[1].course_id ||
      courseId === esolCourseIds?.[0].course_id
    ) {
      console.log("courseId i am here insert tag user", courseId);
      insertTagUser();
    } else {
      null;
    }
    history.push(`/courses/${courseId}`);
  };
  return (
    <div>
      <a
        onClick={() => handleCheckoutPage(slide.courseId)}
        style={{ cursor: "pointer" }}
      >
        <img
          src={slide.bannerPath}
          alt={`Banner for course ${slide.courseId}`}
        />
      </a>
    </div>
  );
};

export default EsolAdvertisement;
