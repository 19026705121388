import {
  GET_USER_COURSES,
  GET_USER_DEMO_BATCHES,
} from "graphql/subscriptions/Course";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchCoupons,
  FetchUserMoodleCourses,
  AppendCourses,
  SET_DEMO_BATCHES,
} from "redux/actions/Course";
import courseSvc from "services/Course";
import { useSubscription } from "utils/hooks";
import { COURSE_STATUS } from "api/Consts";

export default function CourseWrapper(props) {
  const { course: courseStore, auth } = useSelector(({ course, auth }) => ({
    course,
    auth,
  }));
  const userId = auth?.user?.db_user?.id;
  const dispatch = useDispatch();

  /*
   *
   *
   *
   * User data
   *
   *
   *
   */
  const { data: userEnrollments } = useSubscription(GET_USER_COURSES, {
    variables: { user_id: userId },
  });
  useEffect(() => {
    // Purchased courses
    console.log("userEnrollmentsuserEnrollments", userEnrollments);

    const activeCourses = userEnrollments?.courses_user_course_enrolment.map(
      (e) => {
        if (e.course_complete_obj)
          e.course_relation_obj.status = COURSE_STATUS.COMPLETED;
        else e.course_relation_obj.status = COURSE_STATUS.ONGOING;
        return e.course_relation_obj;
      }
    );

    console.log("activeCoursesactiveCourses", activeCourses);

    if (activeCourses) dispatch(AppendCourses(activeCourses));
  }, [userEnrollments]);

  const { data: userDemoSlots } = useSubscription(GET_USER_DEMO_BATCHES, {
    variables: { user_id: userId },
  });

  useEffect(() => {
    if (userDemoSlots) {
      // Demo courses
      const demoBatches = userDemoSlots.courses_user_batch_slots
        .map((e) => e.batch_slot)
        .filter((bs) => !courseStore.active_courses[bs.batch.course_id]);

      if (demoBatches && demoBatches.length > 0) {
        (async () => {
          const finalDemoBatches = demoBatches.filter((bs) => {
            return !(courseStore.active_courses || {})[bs.batch.course_id];
          });
          await courseSvc.refreshCoursesEnrolledStudents();
          finalDemoBatches.forEach((bs) => {
            courseSvc.postProcessCourseData(bs.batch.course);
          });

          dispatch({
            type: SET_DEMO_BATCHES,
            payload: finalDemoBatches,
          });
        })();
      } else dispatch({ type: SET_DEMO_BATCHES, payload: [] });
    }
  }, [userDemoSlots, courseStore.active_courses]);

  /*
   *
   *
   *
   * Bootstrap
   *
   *
   *
   */
  useEffect(() => {
    if (auth.authenticated) {
      if (auth.onboarding === false) {
        // Fetch courses
        dispatch(FetchUserMoodleCourses());
        // Fetch user's coupons
        dispatch(FetchCoupons(userId));
      }
    }
  }, [auth.onboarding, auth.authenticated]);

  return <>{props.children}</>;
}
