import { COURSE_STATUS } from "api/Consts";
import { getMoodleUserPersistently, get_moodle_courses } from "api/MoodleApi";
import _ from "lodash";
import courseSvc from "services/Course";
import Toast from "wrappers/Toast";
import liveClassService from "services/liveClass";

export const SET_COUPON_IN_FOCUS = "set-coupon-in-focus";
export const SET_APPLYING_COUPON = "set-applying-coupon";
export const UNSET_COUPON_IN_FOCUS = "unset-coupon-in-focus";
export const APPEND_COURSES = "append-courses";
export const SET_DEMO_BATCHES = "set-demo-batches";
export const SET_COUPONS = "set-coupons";
export const SET_COURSE_TO_PURCHASE = "set-course-to-purchase";
export const SET_SEARCH_PROPS = "set-search-props";
export const SET_COURSE_PROGRESS = "set-course-progress";
export const SET_COURSE_TO_DEMO = "set-course-to-demo";
export const SET_USER_ENROLLED_COURSES = "set-user-enrolled-courses";
export const SET_BATCH_SLOT_TO_JOIN = "set-batch-slot-to-join";
export const SET_BATCH_TO_JOIN = "set-batch-to-join";
export const SET_COURSE_LESSON_AND_TOPICS = "set-course-topics";
export const OPEN_COURSE_RESOURCES = "open-course-resources";
export const OPEN_COURSE_COMPLETION_SETTINGS =
  "open-course-completion-settings";

// Fetch user's moodle courses courses also fetches any available demo batches
export const FetchUserMoodleCourses = (hooks) => {
  const { onEnd } = hooks || {};
  return async (dispatch) => {
    // try {
    //   const [moodleRes, hasuraRes] = await Promise.all([
    //     get_moodle_courses(),
    //     courseSvc.getDefaultCourses(),
    //   ]);
    //   const moodleCourses = _.cloneDeep(moodleRes).data;
    //   const hasuraCourses = _.cloneDeep(hasuraRes);
    //   const activeCourses = [];
    //   hasuraCourses.forEach((hc) => {
    //     const moodleCourse = (hc.moodleCourse = moodleCourses.find(
    //       (mc) => hc.is_moodle_course && mc.id == hc.moodle_course_id
    //     ));
    //     if (moodleCourse) {
    //       hc.status =
    //         moodleCourse.completed || moodleCourse.progress == 100
    //           ? COURSE_STATUS.COMPLETED
    //           : COURSE_STATUS.ONGOING;
    //       hc.completed = moodleCourse.compeleted;
    //       hc.progress = Math.round(moodleCourse.progress || 0);
    //       hc.starttime = moodleCourse.starttime;
    //       activeCourses.push(hc);
    //     }
    //   });
    //   dispatch(AppendCourses(activeCourses));
    //   if (onEnd) onEnd();
    // } catch (err) {
    //   console.error(err);
    // }
  };
};

export const FetchCoupons = (userId) => {
  return async (dispatch) => {
    const coupons = await courseSvc.getUserCouponCodes(userId);
    dispatch(SetCoupons(coupons));
  };
};

export const SetCouponInFocus = (code) => {
  return async (dispatch) =>
    dispatch({ type: SET_COUPON_IN_FOCUS, payload: { code } });
};

export const UnsetCouponInFocus = () => {
  return async (dispatch) => {
    dispatch({ type: UNSET_COUPON_IN_FOCUS });
    dispatch({ type: SET_APPLYING_COUPON, payload: false });
  };
};

export const AppendCourses = (courses) => {
  return (dispatch) => dispatch({ type: APPEND_COURSES, payload: courses });
};

export const SetCoupons = (coupons) => {
  return (dispatch) => dispatch({ type: SET_COUPONS, payload: coupons });
};

export const SetCourseLessons = (lessons) => {
  console.log("checkCourseCompleteAudit", "invoked setCourseLessons");
  return (dispatch) =>
    dispatch({ type: SET_COURSE_LESSON_AND_TOPICS, payload: lessons });
};

export const ApplyCoupon = (code, userId, hooks) => {
  const { onEnd } = hooks || {};
  return async (dispatch) => {
    // // Wait for moodle user to be created
    // const loader = Toast.load("Applying coupon. It may take upto 2 minutes...");
    // await getMoodleUserPersistently();
    // Toast.endLoader(loader);

    // Continue when user exists
    let coupons;
    try {
      coupons = await courseSvc.applyCouponCode(code, userId);
      Toast.success("Coupon applied successfully");
    } catch (err) {
      console.log("Apply coupon error", err);
      Toast.error(err.msg);
    }

    if (coupons) {
      dispatch(SetCoupons(coupons));
      dispatch(FetchUserMoodleCourses());
    }

    dispatch(UnsetCouponInFocus());

    if (typeof onEnd === "function") onEnd();
  };
};

export const SetCourseToPurchase = (courseId) => {
  return (dispatch) =>
    dispatch({ type: SET_COURSE_TO_PURCHASE, payload: { courseId } });
};

export const SetSearchProps = (searchProps) => {
  return (dispatch) =>
    dispatch({ type: SET_SEARCH_PROPS, payload: searchProps });
};

export const SetViewResource = (viewResource) => {
  return (dispatch) =>
    dispatch({ type: OPEN_COURSE_RESOURCES, payload: viewResource });
};
/******Course Progress for React Course Builder */

export const FetchCourseProgress = (userId) => {
  return async (dispatch) => {
    const usercourse = await liveClassService.getUserCourse(userId);

    if (usercourse) {
      usercourse.length > 0 &&
        usercourse.map(async (course) => {
          const course_id = course.course_relation_obj.id;

          const resdata = await liveClassService.getCourseCompletionTopics(
            course_id
          );
          const finallength = resdata.length;

          const userRep = await liveClassService.GetUserCourseCompletedTopics(
            course_id,
            userId
          );

          const getDiff = getDifference(userRep, resdata);

          let perc = ((finallength - getDiff.length) / finallength) * 100;
          let currentProgress = 100 - perc;
          let array = {
            course_id: course_id,
            currentProgress: isNaN(currentProgress) ? 0 : currentProgress,
          };

          dispatch({ type: SET_COURSE_PROGRESS, payload: array });
        });
    }
  };
};

function getDifference(array1, array2) {
  return array1.filter((object1) => {
    return array2.some((object2) => {
      return object1.mapping_id === object2.mapping_id;
    });
  });
}

// Get user's enrolled courses
export const UserEnrolledCourses = (userId, hooks) => {
  console.log("hey i am here", userId, hooks);

  const { onEnd } = hooks || {};
  return async (dispatch) => {
    try {
      const userCourseRes = await liveClassService.getUserCourse(userId);
      const userEnrollCourses = _.cloneDeep(userCourseRes);

      dispatch(
        AppendCourses(
          userEnrollCourses.map((enrollment) => enrollment.course_relation_obj)
        )
      );
      if (onEnd) onEnd();
    } catch (err) {
      console.error(err);
    }
  };
};

export const FetchCourseLesson = (
  course_id,
  user_id,
  invokedAfterCompletingAttempt
) => {
  console.log("checkCourseCompleteAudit", "invoked FetchCourseLesson");
  return async (dispatch) => {
    const loader =
      invokedAfterCompletingAttempt &&
      Toast.load("Finishing your attempt. Please wait...");
    const lessons = await courseSvc.getCourseLessonTopics(course_id, user_id);
    invokedAfterCompletingAttempt && Toast.endLoader(loader);
    dispatch(SetCourseLessons(lessons));
  };
};

export const FindCourseTopicResources = (viewResource, id, lessonResourecs) => {
  return (dispatch) => {
    const resource = {
      active: !viewResource,
      activeMappingID: id,
      lessonResourecs: lessonResourecs,
    };
    dispatch(SetViewResource(resource));
  };
};

export const openCourseCompletionSettings = (status) => {
  return (dispatch) =>
    dispatch({ type: OPEN_COURSE_COMPLETION_SETTINGS, payload: status });
};

/****End *****/
