import { gql } from "@apollo/client";

const COURSE_PARTNER_COLUMNS = `
  id
  course_id
  partner_id
  course {
    id
    full_name
  }
  partner {
    id
    name
    logo
  }
`;

export const COURSES_COLUMNS = `
  id
  full_name
  description
  start_date
  end_date
  moodle_config_id
  moodle_course_url
  redirection_url
  course_category {
    id
    name
  }
  publish
  is_moodle_course
  created_at
  updated_at
  identifier
  cost
  nsqf_level
  discount
  multilang
  image_url
  video_url
  has_certificate
  short_name
  certificate_image_url
  moodle_course_id
  duration
  is_live_course
  is_subscription
  is_taxable
  certificate_templates{
    id
    course_id
    certificate_template_id
  }
  course_type
    partners: certification_partners {
    ${COURSE_PARTNER_COLUMNS}
  }
`;

const COURSE_CATEGORY_COLUMNS = `
  name
  id
  description
  visible
  created_at
  updated_at
  slug
`;

export const COUPON_COLUMNS = `
  id
  created_at
  updated_at
  code
  start_date
  end_date
  active
  user_limit
  discount
  abs_discount
  minimum_cart_amount
  max_usage_per_user
  applicable_for_all_time
  applicable_for_all_users
  partner_id
  num_of_users
  criteria {
    courses {
      course_id
      criteria_id
      courses {
        ${COURSES_COLUMNS}
      }
    }
  }
`;

export const BATCH_SLOT_COLUMNS = `
  id
  batch_id
  platform
  slots_days
  instructor_id
  meeting_link
  event_id
  updated_at
  created_at
  slot_date
  endto_date
  enable_slots
  type
  location
  slot_enrolled_users {
    id
    user {
      id
    }
  }
`;

const BATCH_COLUMNS = `
  id
  from_date
  to_date
  slot_start_time
  slot_end_time
  batch_name
  course_id
  instructor_id
  slots_days
  platform
  min_learners
  max_learners
  partner_id
  meeting_link
  repeat_end_time
  created_at
  updated_at
  partner_project
  project_id
  is_demo
  course {
    id
  }
`;

export const GET_CRITERIA_COURSES = gql`
  query getCourseCriteriaCourses($criteria_id: bigint) {
    courses_course_critiera_courses(
      where: { criteria_id: { _eq: $criteria_id } }
    ) {
      id
      criteria_id
      courses {
        course_category_id
        certificate_image_url
        course_category {
          name
          id
          slug
        }
        description
        end_date
        full_name
        id
        created_at
        moodle_config {
          id
          moodle_server_acs_url
          moodle_sp_client_id
          moodle_sp_name
          moodle_sp_redirect_url
          moodle_sp_secret_key
          created_at
        }
        moodle_course_url
        moodle_config_id
        publish
        start_date
      }
      course_id
    }
  }
`;

export const FETCH_COURSE_BY_ID = gql`
  query ($courseId: bigint) {
    courses_course (
      where: {
        id: { _eq: $courseId }
      }
    ) {
      ${COURSES_COLUMNS}
      course_subscription_relation_array(
        where: { current_plan: { _eq: true } }
      ) {
        interval
        period
        razorpay_plan_id
        subscription_cost
      }
      tax_relation {
        course_tax_relation_object {
          tax_name
          tax_percentage
        }
      }
      demo_batches: batches (where: {
        is_demo: { _eq: true },
        to_date: { _gt: now }
      }) {
        ${BATCH_COLUMNS}
        available_batch_slots: batch_slots (where: {
          slot_date: { _gt: now },
        }) {
          ${BATCH_SLOT_COLUMNS}
          batch { max_learners }
        }
      }
    }
  }
`;

export const FETCH_COURSES_BY_ID = gql`
query ($courseIds: [bigint]) {
  courses_course (
    where: {
      id: { _in: $courseIds }
    }
  ) {
    ${COURSES_COLUMNS}
    course_subscription_relation_array(
      where: { current_plan: { _eq: true } }
    ) {
      interval
      period
      razorpay_plan_id
      subscription_cost
    }
    tax_relation {
      course_tax_relation_object {
        tax_name
        tax_percentage
      }
    }
    demo_batches: batches (where: {
      is_demo: { _eq: true },
      to_date: { _gt: now }
    }) {
      ${BATCH_COLUMNS}
      available_batch_slots: batch_slots (where: {
        slot_date: { _gt: now },
      }) {
        ${BATCH_SLOT_COLUMNS}
      }
    }
  }
}
`;

export const FETCH_COURSES_BY_PARTNER = gql`
  query ($partnerId: bigint) {
    courses_course_criteria(
      where: {
        partner_id: { _eq: $partnerId }
        apply_to_skillstrainer: { _eq: true }
      }
    ) {
      courses {
        courses {
          ${COURSES_COLUMNS}
        }
      }
    }
  }
`;

export const FETCH_DEFAULT_COURSES = gql`
  query {
    courses_course (
      where: {
        publish: { _eq: true }
      }
    ) {
      ${COURSES_COLUMNS}
      course_subscription_relation_array {
        period
        interval
        course_id
        razorpay_plan_id
        razorpay_plan_creation_date
      }
      tax_relation_array {
        course_tax_relation_object {
          tax_name
          tax_percentage
        }
      }
      demo_batches: batches (where: {
        is_demo: { _eq: true },
        to_date: { _gt: now }
      }) {
        ${BATCH_COLUMNS}
        available_batch_slots: batch_slots (where: {
          slot_date: { _gt: now },
        }) {
          ${BATCH_SLOT_COLUMNS}
        }
      }
    }
  }
`;

export const FETCH_DEFAULT_CATEGORIES = gql`
  query {
    courses_course_categories (
      where: {
        visible: { _eq: true }
      }
    ) {
      ${COURSE_CATEGORY_COLUMNS}
    }
  }
`;

export const GET_BATCH_SLOTS_BY_COURSE_ID = gql`
  query getbatchslotsbycourseid($course_id: Int) {
    courses_course_batches(
      where: {
        course_id: { _eq: $course_id }
        _or: [{ is_demo: { _eq: false } }, { is_demo: { _is_null: true } }]
      }
    ) {
      batch_name
      slots_days
      repeat_end_time
      platform
      id
      from_date
      to_date
      slot_start_time
      slot_end_time
      course {
        full_name
        id
      }
    }
  }
`;

export const GET_USER_BATCH_SLOTS_BY_USER_AND_BATCH_ID = gql`
  query getuserbatchslots($batch_id: Int) {
    courses_batch_slots(where: { batch_id: { _eq: $batch_id } }) {
      batch_id
      endto_date
      slot_date
      slots_days
      meeting_link
      batch {
        course {
          full_name
          id
        }
      }

      instructor {
        name
        email
      }
    }
  }
`;

export const GET_USER_BATCH_ENROLLMENT_BY_COURSE_ID = gql`
  query getuserbatchslotsbycourseid($user_id: Int, $course_id: Int) {
    courses_user_batch_enrollment(
      where: { user_id: { _eq: $user_id }, course_id: { _eq: $course_id } }
    ) {
      course {
        full_name
        id
      }
      batch {
        id
        batch_name
      }
      Batch_slots {
        endto_date
        slot_date
        slots_days
        batch_id
        meeting_link
        type
        instructor {
          name
          email
        }
      }
    }
  }
`;

export const GET_USER_EMAIL_BY_ID = gql`
  query getUserEmailById($id: bigint) {
    courses_users(where: { id: { _eq: $id } }) {
      email
      name
    }
  }
`;
export const FETCH_COURSE_CATEGORIES = gql`
  query {
    courses_course_categories (
      where: {
        visible: { _eq: true }
      },
      order_by: {
        name: asc
      }
    ) {
      ${COURSE_CATEGORY_COLUMNS}
    }
  }
`;

export const FETCH_COUPON_BY_CODE = gql`
  query ($code: String) {
    courses_st_coupons_configuration (
      where: {
        code: { _eq: $code }
      }
    ) {
      ${COUPON_COLUMNS}
    }
  }
`;

export const FETCH_COUPON_BY_USER_QUERY = gql`
  query ($userId: bigint) {
    courses_st_user_coupons(where: { user_id: { _eq: $userId } }) {
      coupon_id
      user_id
      coupon: st_coupons_configuration {
        ${COUPON_COLUMNS}
      }
    }
  }
`;

export const FETCH_USER_COUNT_BY_COUPON_QUERY = gql`
  query ($couponId: uuid) {
    courses_st_user_coupons_aggregate(
      where: { coupon_id: { _eq: $couponId } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const COURSE_BATCHES_EXIST_OR_NOT = gql`
  query BatchesCourses {
    courses_course_batches {
      course_id
    }
  }
`;

export const GET_USER_COURSES = gql`
  query GetUserCourse($user_id: Int) {
    courses_user_course_enrolment(
      where: { user_id: { _eq: $user_id }, enroll_status: { _eq: true } }
    ) {
      course_relation_obj {
        cost
        course_category_id
        certificate_image_url
        created_at
        description
        discount
        duration
        end_date
        full_name
        id
        identifier
        image_url
        is_live_course
        is_moodle_course
        live_class_duration
        moodle_config_id
        moodle_course_id
        moodle_course_url
        nsqf_level
        publish
        short_name
        start_date
        redirection_url
        certificate_templates {
          id
          course_id
          certificate_template_id
        }
        course_category {
          id
          name
        }
      }
      course_id
      course_complete_obj {
        completed
        percentage
      }
    }
  }
`;

export const GET_COURSE_BATCHES = gql`
  query getCourseBatches($courseId: Int) {
    courses_course_batches (
      where: {
        course_id: { _eq: $courseId }
      }
    ) {
      ${BATCH_COLUMNS}
    }
  }
`;

export const GET_DEMO_BATCHES_BY_COURSE_ID = gql`
  query getDemoBatchesByCourse($courseId: bigint) {
    courses_course_batches (
      where: {
        course_id: { _eq: $courseId }
        is_demo: { _eq: true }
      }
    ) {
      ${BATCH_COLUMNS}
      batch_slots {
        ${BATCH_SLOT_COLUMNS}
      }
    }
  }
`;

export const GET_BATCH_SLOTS_BY_USER_ID = gql`
  query getDemoBatchesByUser($userId: bigint) {
    courses_user_batch_slots (
      where: {
        user_id: { _eq: $userId },
        batch_slot: {
          endto_date: { _gt: now }
        }
      }
    ) {
      batch_slot {
        ${BATCH_SLOT_COLUMNS}
        batch {
          course {
            ${COURSES_COLUMNS}
          }
        }
      }
    }
  }
`;

export const FETCH_COURSES_MOODLE_DATA = gql`
  query {
    courses_course {
      id
      is_moodle_course
      moodle_course_id
      discontinued_from_moodle_at
      moodle_user_stats {
        total_users
      }
    }
  }
`;

export const FETCH_COURSE_ENROLLMENT_COUNT = gql`
  query ($courseId: Int) {
    courses_user_course_enrolment_aggregate(
      where: { course_id: { _eq: $courseId } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

// export const FETCH_COURSE_ENROLLMENT_COUNT = gql`
//   query ($courseId: Int, $discontinuedFromMoodleAt: timestamptz) {
//     courses_user_course_enrolment_aggregate(
//       where: {
//         course_id: { _eq: $courseId }
//         created_at: { _gt: $discontinuedFromMoodleAt }
//       }
//     ) {
//       aggregate {
//         count
//       }
//     }
//   }
// `;

export const GET_BATCH_BY_ID = gql`
  query($batch_id: Int) {
    courses_course_batches(
      where: {
        id: { _eq: $batch_id }
      }
    ) {
      ${BATCH_COLUMNS}
      batch_slots {
        ${BATCH_SLOT_COLUMNS}
      }
    }
  }
`;

export const GET_BATCH_SLOT_BY_ID = gql`
  query ($batch_slot_id: Int) {
    courses_batch_slots(
      where: {
        id: { _eq: $batch_slot_id }
      }
    ) {
      ${BATCH_SLOT_COLUMNS}
      batch {
        ${BATCH_COLUMNS}
        course {
          ${COURSES_COLUMNS}
        }
      }
    }
  }
`;

export const GET_BATCH_SLOT_BY_ID_SUB = `
  subscription($batch_slot_id: Int) {
    courses_batch_slots(
      where: {
        id: { _eq: $batch_slot_id }
      }
    ) {
      ${BATCH_SLOT_COLUMNS}
    }
  }
`;
