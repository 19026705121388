import { BATCH_SLOT_COLUMNS, COURSES_COLUMNS } from "graphql/queries/Courses";

export const GET_USER_COURSES = `
  subscription ($user_id: Int) {
    courses_user_course_enrolment(
      where: { user_id: { _eq: $user_id }, enroll_status: { _eq: true } }
    ) {
      course_relation_obj {
        cost
        course_category_id
        created_at
        description
        discount
        duration
        end_date
        full_name
        id
        identifier
        image_url
        is_live_course
        is_moodle_course
        live_class_duration
        moodle_config_id
        moodle_course_id
        moodle_course_url
        nsqf_level
        publish
        short_name
        redirection_url
        start_date
        certificate_image_url
        certificate_templates{
          id
          course_id
          certificate_template_id
        }
        course_category {
          id
          name
        }
      }
      course_id
      course_complete_obj {
        completed
        percentage
      }
    }
  }
`;

export const GET_USER_DEMO_BATCHES = `
  subscription ($user_id: bigint) {
    courses_user_batch_slots (
      where: {
        user_id: { _eq: $user_id }
      }
      order_by: {
        batch_slot: {
          slot_date: desc
        }
      }
    ) {
      batch_slot {
        ${BATCH_SLOT_COLUMNS}
        batch {
          course_id
          course {
            ${COURSES_COLUMNS}
          }
        }
      }
    }
  }
`;

export const COURSE_BATCHES_EXIST_OR_NOT = `
  subscription BatchesCourses {
    courses_course_batches {
      course_id
    }
  }
`;
