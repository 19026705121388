import { SignJWT } from "jose";
import { jwt_key } from "api/Consts";

export const createQuartzSSOJWT = async (key, logOnAs) => {
  if (typeof key !== "string" || !key) {
    console.error("Invalid signing key:", key);
    return null;
  }

  const payload = {
    iat: Math.floor(Date.now() / 1000),
    exp: Math.floor(Date.now() / 1000) + 60 * 5,
    logOnAs: logOnAs,
    iss: "http://quartz-system.com",
    aud: "http://quartz-system.com",
  };

  console.log("payload", payload);

  try {
    const encoder = new TextEncoder();
    const encodedKey = encoder.encode(key);

    const jwt = await new SignJWT(payload)
      .setProtectedHeader({ alg: "HS256", typ: "JWT" })
      .sign(encodedKey);
    return jwt;
  } catch (error) {
    console.error("Error signing JWT:", error);
    return null;
  }
};

export const redirectToQuartzSSO = async (email) => {
  const key = jwt_key;
  const logOnAs = email;

  const jwtToken = await createQuartzSSOJWT(key, logOnAs);

  if (jwtToken) {
    const quartzUrl = `https://quartzweb.nocn.org.uk/QuartzWeb_Test/Web/SSO.aspx?jwt=${jwtToken}&Page=/R/MyLearning/MyEAssessments`;
    window.location.href = quartzUrl;
  } else {
    console.error("Failed to generate JWT token.");
  }
};
