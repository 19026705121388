import React, { useState } from "react";
import { useSelector } from "react-redux";
import courseSvc from "services/Course";
import { useHistory } from "react-router-dom";
import liveClassService from "services/liveClass";
import Toast from "utils/Toast";
import { esolCourseIds } from "api/Consts";
import { redirectToQuartzSSO } from "utils/createQuartzSSOJWT";

export default function UserCompletedCourses(props) {
  const courses = props.data;
  console.log("coursescourses", courses);

  const history = useHistory();
  const [maxRange, setMaxRange] = useState(props.maxRange);

  const [downloadProgress, setDownloadProgress] = useState({
    status: false,
    course_id: null,
  });
  const loadMore = () => {
    setMaxRange((prevRange) => prevRange + 3);
  };

  const auth = useSelector(({ auth }) => auth);
  const userId = auth.user?.db_user?.id;
  const email = auth.user?.db_user?.email;

  const changeDownloadName = async (url, filename) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  };

  const DownloadCert = async (courseId, params) => {
    const response = await liveClassService.getUserCertificateData(
      courseId,
      userId
    );
    if (params.download)
      await changeDownloadName(
        response.certificate_pdf_url,
        `${User.name.replace(" ", "-")}-${User.id}`
      );
    else if (params.view) window.open(response.certificate_pdf_url, "_blank");
  };

  const moodleCertificateDownload = async (course_id) => {
    const response = await courseSvc.downloadCertificate({
      courseId: course_id,
    });

    if (response.status) {
      window.open(response?.download_link, "_blank");
    } else {
      Toast.error("No Certificate found for this course");
    }

    setDownloadProgress({
      status: false,
      course_id: null,
    });
  };

  return (
    <div>
      <div className="bg-white p-2">
        {courses && courses.length > 0 ? (
          <div className="flex flex-row grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-4 gap-x-4">
            {courses.slice(0, maxRange).map((data) => {
              return (
                <div
                  key={data.id}
                  className="w-full lg:w-full md:mx-2 mb-4 md:mb-0 "
                >
                  <div className="my-4">
                    <div className="flex w-full">
                      <button
                        onClick={() => {
                          if (data.is_moodle_course) {
                            courseSvc.openCourse({
                              moodle_course_id: data.moodle_course_id,
                              courseId: data.id,
                            });
                          } else {
                            history.push(`/course/view/${data.id}`);
                          }
                        }}
                        className="block text-japanese_indigo font-semibold mb-2 text-lg md:text-base lg:text-lg"
                      >
                        {data.full_name}
                      </button>
                    </div>
                  </div>
                  <div className="bg-white rounded-lg overflow-hidden relative flex flex-col justify-between">
                    <div className="w-full">
                      <img
                        className="h-56 w-full object-cover object-center"
                        src={data.certificate_image_url}
                        alt="course-img"
                      />
                    </div>
                    <div className="w-full relative mt-2">
                      <button
                        onClick={() => {
                          console.log("user complete courses", data);

                          if (data.is_moodle_course) {
                            courseSvc.openCourse({
                              moodle_course_id: data.moodle_course_id,
                              courseId: data.id,
                            });
                          } else if (data.redirection_url) {
                            redirectToQuartzSSO(email);
                          } else {
                            history.push(`/course/view/${data.id}`);
                          }
                        }}
                        className="border border-secondary text-japanese_indigo font-semibold text-lg py-2 w-full rounded-md my-2"
                      >
                        View Course
                      </button>
                      {(data.certificate_templates?.length !== 0 ||
                        data.is_moodle_course) && (
                        <button
                          className="bg-orange text-white font-semibold text-lg py-2 w-full rounded-md my-2"
                          onClick={() => {
                            if (data.is_moodle_course) {
                              moodleCertificateDownload(data.id);
                              setDownloadProgress({
                                status: true,
                                course_id: data.id,
                              });
                            } else {
                              DownloadCert(data.id, { download: true });
                            }
                          }}
                        >
                          {downloadProgress.status &&
                          downloadProgress.course_id === data.id
                            ? "Please Wait...."
                            : "Download Certificate"}
                        </button>
                      )}
                      {/* {!data.is_moodle_course &&
                        data.certificate_templates?.length !== 0 && (
                          <button
                            className="btn-secondary w-full text-lg py-2"
                            onClick={() =>
                              DownloadCert(data.id, { view: true })
                            }
                          >
                            View Certificate
                          </button>
                        )} */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="w-full box-border p-4 text-2xl font-semibold text-gray-400 text-center">
            There are no completed courses
          </div>
        )}
      </div>
    </div>
  );
}
