import { useCallback, useEffect, useId, useRef, useState } from "react";
import { socket } from "./websocket";

export const useLoader = () => {
  const [loading, setLoading] = useState(false);
  const stopLoader = () => setLoading(false);
  const startLoader = () => setLoading(true);

  return {
    loading,
    stopLoader,
    startLoader,
    setLoading,
  };
};

let _id = 1;

export const useSubscription = (query, opts) => {
  const { variables } = opts;
  const idRef = useRef(_id++ + "");
  const id = idRef.current;
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const onMessage = (message) => {
    console.log("message", message);

    if (message?.data) {
      if (typeof opts.onCompleted === "function")
        opts.onCompleted(message.data);
      setData(message.data);
      setError();
    } else if (message?.errors) {
      if (typeof opts.onError === "function") opts.onError(message.errors);
      setData();
      setError(message.errors);
    }

    if (loading) setLoading(false);
  };

  const onError = (err) =>
    setError({ message: "Trouble establishing websocket connection" });

  useEffect(() => {
    socket.subscribe({
      id,
      query,
      variables,
      onMessage,
      onError,
    });

    return () => socket.unsubscribe(id);
  }, []);

  useEffect(() => {
    socket.sendMessage({
      id,
      type: "stop",
    });
    socket.sendMessage({
      id,
      payload: {
        query,
        variables,
      },
      type: "start",
    });
  }, [query, JSON.stringify(variables)]);

  return { data, error, loading };
};

export const useEventListener = (event, callback, ref = window) => {
  const listenerRef = useRef({});
  useEffect(() => {
    const { current: { callback: currentCallback, event: currentEvent } = {} } =
      listenerRef;

    const newCallback = callback,
      newEvent = event;

    if (currentCallback) ref.removeEventListener(currentEvent, currentCallback);
    ref.addEventListener(newEvent, newCallback);
    listenerRef.current = { callback: newCallback, event: newEvent };

    return () => ref.removeEventListener(currentEvent, currentCallback);
  }, [callback]);
};

export const useWindowEventListener = (eventType, callback, deps = []) => {
  useEffect(() => {
    window.addEventListener(eventType, callback);
    return () => {
      window.removeEventListener(eventType, callback);
    };
  }, [eventType, ...deps]);
};
