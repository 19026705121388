/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import _ from "lodash";

import {
  OnboardingInput,
  OnboardingSelect,
  OnboardingDate,
  OnboardingSelectBig,
} from ".";

import OnboardingDuration, { DURATION_TYPE } from "./onboardingduration";
import {
  ACEDEMIC_QUALIFICATION_DETAILS,
  QUALIFICATION_ID_TO_TYPE,
} from "../../api/Consts";
import Toast from "../../wrappers/Toast";
import { wireEventValue } from "../../utils/func";
import userSvc from "../../services/User";
import { SET_ONBOARDING } from "../../redux/actions/Auth";
import courseSvc from "../../services/Course";
import { getDataFromPincode } from "utils/resources";

export default function OnboardingUser() {
  const auth = useSelector(({ auth }) => auth);
  const user = auth.user?.db_user;
  const username = user?.name;
  const userId = user?.id;
  const dispatch = useDispatch();

  // const { data: qualificationsData, loading: QualificationsLoading } =
  //   useQuery(GET_QUALIFICATIONS);
  // Form requirements
  const [courseCategories, setCourseCategories] = useState([]);
  useEffect(() => {
    courseSvc
      .getCourseCategories()
      .then(setCourseCategories)
      .catch(console.error);
  }, []);
  // End form requirements

  const [inputFields, setInputFields] = useState([
    [
      {
        type: "select",
        id: "highest_qualification",
        displayName: "Highest Educational Qualification *",
        placeHolder: "No qualification",
        options: Object.values(ACEDEMIC_QUALIFICATION_DETAILS).map((q) => {
          return {
            label: q.label,
            value: q.qualification_id,
          };
        }),
        validator: yup
          .string()
          .required("Please choose your highest qualification"),
      },
      {
        type: "duration",
        durationType: DURATION_TYPE.yearsAndMonths,
        id: "work_experience",
        displayName: "Work Experience",
        placeHolder: "Your Work Experience",
        optional: true,
        validator: yup
          .object()
          .shape({ months: yup.string(), years: yup.string() })
          .nullable(),
      },
      // {
      //   type: "date",
      //   id: "dob",
      //   displayName: "DOB*",
      //   validator: yup
      //     .string()
      //     .required("Please enter a date of birth")
      //     .test(
      //       "is-valid",
      //       "Year of birth should be between 1910 and 2010",
      //       (value) => {
      //         const year = value.split("-")[0];
      //         return year >= 1910 && year <= 2010;
      //       }
      //     ),
      // },
      {
        type: "text",
        id: "pincode",
        displayName: "Pincode*",
        validator: yup
          .string()
          .min(6, "Pincode should have 6 digits")
          .max(6, "Pincode shpuld have 6 digits")
          .matches("^(?!0)([0-9])")
          .required(),
      },
      {
        type: "select-big",
        id: "interest",
        displayName: "Your Interest (You can choose both also)*",
        placeHolder: 1,
        validator: yup
          .array()
          .of(yup.string())
          .required("Please choose your interest"),
        options: [
          {
            value: "search",
            text: "Search a Job",
            Image: () => (
              <img
                className="mx-auto my-4"
                alt="user-option"
                src="./onboarding-job.png"
              />
            ),
          },
          {
            value: "learn",
            text: "Learn a New Skill",
            Image: () => (
              <img
                className="mx-auto my-4"
                alt="user-option"
                src="./onboarding-learn.png"
              />
            ),
          },
        ],
      },
    ],
  ]);

  const [errors, setErrors] = useState();
  const getField = (id) => {
    let foundField;
    for (const page of inputFields) {
      const field = page.find((f) => f.id === id);
      if (field) {
        foundField = field;
        break;
      }
    }
    return foundField;
  };
  const updateField = (id) => (value) => {
    let field = getField(id);
    field.value = value;
    setInputFields([...inputFields]);
  };

  const [currentInputScreen, setCurrentInputScreen] = useState(0);
  const handleSkip = async () => {
    await userSvc.createOrUpdateUserTag({
      user_id: userId,
      name: "onboarded",
      value: "1",
    });
    Toast.success("Welcome!");
    dispatch({ type: SET_ONBOARDING, payload: false });
  };
  const handleScreenChange = async (change) => {
    if (currentInputScreen + change >= 0) {
      if (change > 0) {
        // If form is moving forward
        const fields = inputFields[currentInputScreen];
        const schema = {};
        const values = {};
        fields.forEach((field) => {
          schema[field.id] = field.validator;
          values[field.id] = field.value;
        });
        const errors = await yup
          .object()
          .shape(schema)
          .validate(values, { abortEarly: false })
          .then((e) => false)
          .catch((err) => err.errors);

        // console.log("Errors: ", errors);

        if (errors === undefined)
          Toast.error("Please fill all the required fields");
        else if (!errors) {
          if (currentInputScreen + change < inputFields.length)
            // If the current screen is not the last screen
            setCurrentInputScreen(currentInputScreen + change);
          else finish();
          setErrors({});
        } else {
          errors.map((error) => Toast.error(error));
          setErrors(errors);
        }
      } else {
        // If form is moving backward
        setCurrentInputScreen(currentInputScreen + change);
      }
    }
  };

  const [finishing, setFinishing] = useState(false);
  const finish = async () => {
    setFinishing(true);
    try {
      const allFields = inputFields.reduce((acc, cur) => acc.concat(cur));
      const values = {};
      allFields.map((field) => (values[field.id] = field.value));

      const allQuals = Object.values(Array(values.highest_qualification)).map(
        (q) => {
          return {
            qualification_id: String(q),
            qualification_type: String(QUALIFICATION_ID_TO_TYPE[q]),
            fresh: true,
          };
        }
      );
      console.log(
        "🚀 ~ file: onboardinguser.js:214 ~ finish ~ allQuals:",
        allQuals
      );
      const qualResponse = await userSvc.setUserQualifications(
        allQuals,
        userId
      );

      // Work experience
      let weResponse = true;
      if (values.work_experience)
        weResponse = await userSvc.createOrUpdateUserTag({
          user_id: userId,
          name: "work-experience",
          value: `${values.work_experience.years} years and ${values.work_experience.months} months`,
        });

      // DOB
      // const dobResponse = await userSvc.updateUserDetails(userId, {
      //   date_of_birth: new Date(values.dob).toISOString(),
      // });

      //Pincode

      let pin;
      const handlePincodeChange = async (pincode) => {
        await getDataFromPincode({ country: "IND", pincode: pincode })
          .then((res) => {
            console.log(res);
            pin = res;
            return res;
          })
          .catch(() => (pin = null));
      };
      await handlePincodeChange(values.pincode);

      const pincodeAndCountryResponse = await userSvc
        .createOrUpdateAddress({
          user_id: userId,
          pincode: values.pincode,
          city_town: pin.city_town,
          district: pin.district,
          state: pin.state,
          adress_type: "primary",
        })
        .then((data) => {
          return userSvc.attachAddressToUser(data.id, userId);
        });

      // Interest
      const interestResponse = userSvc.createOrUpdateUserTag({
        user_id: userId,
        name: "interest",
        value: JSON.stringify(values.interest),
      });

      if (
        qualResponse &&
        weResponse &&
        // dobResponse &&
        interestResponse &&
        pincodeAndCountryResponse
      ) {
        await userSvc.createOrUpdateUserTag({
          user_id: userId,
          name: "onboarded",
          value: "1",
        });
        Toast.success("Welcome!");
        dispatch({ type: SET_ONBOARDING, payload: false });
      }
      setFinishing(false);
    } catch (err) {
      setFinishing(false);
      throw err;
    }
  };
  return (
    <>
      <section className="min-h-screen flex items-stretch text-white ">
        <div
          className="lg:w-3/5 w-full flex items-center text-left md:px-16 px-0 z-0"
          style={{
            backgroundImage: "url(./onboarding-leftpane.png)",
          }}
        >
          <div className="w-full py-6 z-20">
            <div className="p-3 lg:p-0">
              <h1 className="text-orange text-lg font-medium">
                Hi {username}!
              </h1>
              <h2 className="text-lg lg:text-3xl my-3 text-japanese_indigo font-bold">
                Tell Us About Yourself
              </h2>
              <p className="text-gray-500 text-base font-medium pb-4">
                This is only initial information, you can change it anytime in
                future
              </p>
              <p className="text-gray-500 text-base font-medium pb-4">
                Please fill in your basic details to this form or Skip this form
                for now & fill in details later through your profile section.
              </p>
              Skip Button
              <p className="text-red-500 text-xs italic pb-4">
                Fields marked with asterisk are compulsory
              </p>
              <div className="w-20 border-2 bg-orange border-orange h-0">.</div>
            </div>

            <div className="grid grid-rows-2 lg:grid-cols-2 gap-x-4 w-full p-4 lg:p-0">
              {inputFields[currentInputScreen].map((data) => (
                <div
                  className={`my-5 max-h-32 ${data.type === "date" && "w-72"}`}
                >
                  {data.type === "text" && (
                    <OnboardingInput
                      data={data}
                      onChange={wireEventValue(updateField(data.id))}
                      value={getField(data.id).value}
                    />
                  )}
                  {data.type === "select" && (
                    <OnboardingSelect
                      data={data}
                      onChange={wireEventValue(updateField(data.id))}
                      value={getField(data.id).value}
                    />
                  )}
                  {data.type === "select-big" && (
                    <OnboardingSelectBig
                      data={data}
                      multiple={true}
                      onChange={wireEventValue(updateField(data.id))}
                      value={getField(data.id).value}
                    />
                  )}
                  {data.type === "date" && (
                    <OnboardingDate
                      data={data}
                      onChange={wireEventValue(updateField(data.id))}
                      value={getField(data.id).value}
                    />
                  )}
                  {data.type === "duration" && (
                    <OnboardingDuration
                      onChange={updateField(data.id)}
                      value={getField(data.id).value}
                      {...data}
                    />
                  )}
                </div>
              ))}
            </div>

            <div className="flex w-full mt-24 lg:mt-14 justify-end px-6 lg:px-0">
              <button
                className="bg-orange font-white font-semibold mr-2 rounded-lg p-4"
                onClick={() => handleScreenChange(1)}
              >
                {finishing ? "Please wait..." : "Save & Continue"}
              </button>
              <button
                className="bg-orange font-white font-semibold mr-2 rounded-lg p-4"
                onClick={() => handleSkip()}
              >
                Skip For Now
              </button>
            </div>
          </div>
        </div>

        <div
          className="hidden lg:flex w-2/5 bg-gray-500 bg-no-repeat bg-cover relative items-center"
          style={{
            backgroundImage: "url(./onboarding-banner.png)",
          }}
        >
          <img
            className="hidden lg:block absolute bottom-0 -left-16"
            src="./onboarding-ellipse.png"
            alt="ellipse-img"
          />
        </div>
      </section>
    </>
  );
}
