import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import courseSvc from "services/Course";
import razorpayClassAPi from "services/RazorpayApi";
import razorpaySubscriptionClass from "services/RazorpaySubscriptionApi";
import {
  CourseOverviewAndPurchaseFragment,
  Modal,
  MultiLangBody,
} from "skillstrainer-resource-library";
import { courseFormatter } from "skillstrainer-resource-library/utils/course";
import { toastError } from "utils/error";
import { useHistory } from "react-router";
import Loader from "assets/animated-images/Loader";
import Toast from "wrappers/Toast";
import CheckoutModal from "components/recommendedcourses/checkoutModal";

export default function PurchaseCourse(props) {
  const { params, complete, user } = props;
  const userId = user.id;
  const { courseId, partnerId } = params;

  const [course, setCourse] = useState();
  const [checkOutModalState, setCheckOutModalState] = useState(true);
  const [paymentEvent, setPaymentEvent] = useState({
    type: "full_payment",
    intialized: false,
  });
  const history = useHistory();

  const activeCourses = useSelector(({ course }) => course.active_courses);

  useEffect(() => {
    (async () => {
      const course = await courseSvc.getCourseById(courseId);
      if (!course) {
        Toast.error("The course doesn't exist");
        complete();
        return;
      }

      const userCourses = await courseSvc.getUserCourses(user.id);
      if (userCourses.find((c) => c.id == courseId)) {
        Toast.error("You have already purchased the course");
        history.push("/courses/" + courseId);
        complete();
      } else {
        setCourse(course);
      }
    })();
  }, []);

  /*******Plain Razorpay payment *****/
  const PayByRazorpay = async (
    gst_no = null,
    business_name = null,
    billing_address_id = null
  ) => {
    await razorpayClassAPi
      .RazorpayApi({
        courseId: course.id,
        user,
        couponId: course.coupon_id || undefined,
        partnerId,
        gst_no: gst_no,
        business_name: business_name,
        billing_address_id: billing_address_id,
      })
      .then(() => setPaymentEvent({ ...paymentEvent, intialized: true }))
      .catch(toastApiError);
    setCheckOutModalState(false);
  };

  /*******Free Course Enrollment ******/
  const EnrolNow = async () => {
    const manualEnrol = await courseSvc
      .freeCourseEnrol(courseId, userId, partnerId)
      .then(() => {
        setPaymentEvent({ ...paymentEvent, intialized: true });
        return true;
      })
      .catch((err) => console.log(err));
    setCheckOutModalState(false);

    if (manualEnrol) {
      Toast.success("Your Are Successfully Enrolled into Course");
    } else Toast.error("Something went wrong while Enrolment!");
  };

  /*******Razorpay Subscription ******/
  const SubscribeNow = async () => {
    await razorpaySubscriptionClass
      .SubscriptionProcess({
        courseData: course,
        user,
        partnerId,
      })
      .then(() => setPaymentEvent({ ...paymentEvent, intialized: true }))
      .catch(toastApiError);
    setCheckOutModalState(false);
  };

  const paymentPromiseRef = useRef();
  const startPayment = async (gstdetails = []) => {
    try {
      if ((course.discounted_cost || course.cost) > 0) {
        if (course.is_subscription && paymentEvent.type === "subscription") {
          return SubscribeNow();
        } else if (paymentEvent.type === "full_payment") {
          return PayByRazorpay(...gstdetails);
        }
      } else {
        return EnrolNow();
      }
      return new Promise((resolve) => {
        paymentPromiseRef.current = { resolve };
      }).then(() => {
        complete();
      });
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    if (activeCourses[courseId]) {
      setCheckOutModalState(false);
      paymentPromiseRef.current?.resolve();
    }
  }, [activeCourses]);

  const openCheckoutModal = async (event) => {
    if (course.is_subscription && event.payingBySubscription)
      setPaymentEvent({ ...paymentEvent, type: "subscription" });
    else if (!event.payingBySubscription)
      setPaymentEvent({ ...paymentEvent, type: "full_payment" });
    setCheckOutModalState(true);
  };

  return (
    <div className="">
      <Modal isOpen={true} onClose={complete}>
        {course === undefined && (
          <div className="flex justify-center p-5 w-full ml-4">
            <Loader style={{ width: "130px" }} />
          </div>
        )}
        {course === false && (
          <div className="flex justify-center p-5 w-full">
            <span className="text-lg font-semibold text-gray-400">
              Couldn't find the course
            </span>
          </div>
        )}
        {course && (
          <MultiLangBody>
            <CourseOverviewAndPurchaseFragment
              courseData={courseFormatter(course)}
              onPaymentStarted={openCheckoutModal}
            />
          </MultiLangBody>
        )}
      </Modal>

      {course && (
        <Modal
          isOpen={checkOutModalState}
          onClose={() => setCheckOutModalState(false)}
          width={"30vw"}
        >
          <CheckoutModal
            courseData={course}
            paymentFunction={startPayment}
            setCourseData={setCourse}
            paymentEvent={paymentEvent}
            closeFunction={() => setModalState(false)}
          />
        </Modal>
      )}
    </div>
  );
}
